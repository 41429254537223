.modal-box {
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.mt-4 {
   margin-top: 8px;
}
