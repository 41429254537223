.button {
    padding: 10px 15px;
    background: #f0f8ff;
    color: #010f2c;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 16px;
    margin: 4px 0;
}
