.first-container-citation {
    border-left: 0.12em solid #313131;
    border-right: 0.12em solid #313131;
    border-bottom: 0.12em solid #313131;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.line-container-citation {
    position: relative;
    padding: 20px 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
