.orderFree {
    background-color: darkgreen;
    color: white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

.orderBusy {
    background-color: darkred;
    color: white;
    text-align: center;
    font-weight: bold;
}

