.team-main {
    padding: 0 16px;
    font-size: 16px;
}
.team-main ul{
    padding-left: 16px;
}
.team-main ul li{
    padding: 1px 0;
}
.first-container-team {
    padding-top: 10px;
    border-right: 0.12em solid #313131;
    border-left: 0.12em solid #313131;
    border-bottom: 0.12em solid #313131;
}
@media screen and (max-width: 1024px) {
    .team-main {
        padding: 0 16px;
        font-size: 15px;
    }
    .first-container-team {
        padding-top: 35px;
    }
}
@media screen and (max-width: 912px) {
    .team-main {
        padding: 0 16px;
        font-size: 15px;
    }
    .box-citation, .box-three-subtitle {
        font-size: 15px;
    }
    .first-container-team {
        padding-top: 64px;
    }
}
@media screen and (max-width: 820px) {
    .team-main {
        padding: 0 16px;
        font-size: 15px;
        padding-bottom: 18px;
    }
    .box-citation, .box-three-subtitle {
        font-size: 15px;
    }
    .first-container-team {
        padding-top: 64px;
    }
}

@media screen and (max-width: 600px) {
    .box-citation, .box-three-subtitle {
        font-size: 14px;
    }
    .first-container-team {
        padding-top: 46px;
    }
}
@media screen and (max-width: 540px) {
    .box-citation, .box-three-subtitle {
        font-size: 13px;
    }
    .first-container-team {
        padding-top: 46px;
    }
}
@media screen and (max-width: 480px) {
    .box-three-subtitle {
        height: 133px!important;
        border-bottom: 0.12em solid #313131;
    }
    .team-main {
        padding: 0 16px;
        font-size: 14px;
        padding-bottom: 18px;
    }
}
@media screen and (max-width: 414px) {
    .team-main {
        padding: 0 12px;
        font-size: 13px;
        padding-bottom: 18px;
    }
    .box-citation, .box-three-subtitle {
        font-size: 12px;
    }
    .first-container-team {
        padding-top: 46px;
    }
}
@media screen and (max-width: 360px) {
    .team-main {
        padding: 0 8px;
        font-size: 12px;
        padding-bottom: 18px;
    }
    .box-citation, .box-three-subtitle {
        font-size: 12px;
        padding-bottom: 6px;
    }
    .first-container-team {
        padding-top: 46px;
    }
    .box-three-subtitle {
        height: 140px!important;
        border-bottom: 0.12em solid #313131;
    }
}
@media screen and (max-width: 320px) {
    .team-main {
        padding: 0 8px;
        font-size: 12px;
        padding-bottom: 18px;
    }
    .box-citation {
        font-size: 12px;
        padding-bottom: 6px;
    }
    .first-container-team {
        padding-top: 46px;
    }
    .box-three-subtitle {
        font-size: 12px;
        height: 156px!important;
        border-bottom: 0.12em solid #313131;
    }
}
